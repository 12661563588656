import JQuery from "jquery";
import { THorizontalCarrouselConfig } from "./types/THorizontalCarrouselConfig";

export default class HorizontalCarrousel {
  private mainContainer: JQuery<HTMLElement>;
  private sliderWrapper: JQuery<HTMLElement>;
  private slider: JQuery<HTMLElement>;
  private controls: JQuery<HTMLElement>;
  private controlNavButtons: JQuery<HTMLElement>;
  private tabsContainer: JQuery<HTMLElement>;
  private slideTabClone: JQuery<HTMLElement>;
  private tabsInventory: JQuery<HTMLElement>;
  private readonly autoplay: boolean;
  private readonly speed: number;
  private animationInterval: number;
  private delayedSlideShiftTimer?: number;
  private config: THorizontalCarrouselConfig;

  constructor(config: THorizontalCarrouselConfig) {
    this.config = config;
    this.mainContainer = $(config.container).first();

    this.sliderWrapper = this.mainContainer.find(".carrousel-slider-wrapper");
    this.slider = this.sliderWrapper.find(".carrousel-slider");
    this._slides = this.slider.find(".slide");

    this.controls = this.mainContainer.find(".controls");
    this.controlNavButtons = this.controls.find(".control-button");
    this.tabsContainer = this.mainContainer.find(".tabs-container");
    this.slideTabClone = this.mainContainer.find(".slide-tab.clone");
    this.tabsInventory = $();

    this._currentIndex = 0;
    this.autoplay = config.autoplay;
    this.speed = config.speed;

    this.animationInterval = null;
    this.initCarrousel(config.shiftSlide);
  }

  private _slides: JQuery<HTMLElement>;

  get slides(): JQuery<HTMLElement> {
    return this._slides;
  }

  private _currentIndex: number;

  get currentIndex(): number {
    return this._currentIndex;
  }

  public GetSlideAt(index: number): HTMLElement {
    return this.slides[index];
  }

  public AddSlide(newSlide: JQuery<HTMLElement>): void {
    newSlide.attr("data-index", this._slides.length);
    this._slides = this._slides.add(newSlide);
  }

  /**
   * Rebuild the slide list and reinitialize its event.
   * Useful if a slide got deleted
   * @constructor
   */
  public RefreshSlides(): void {
    this._slides = this.slider.find(".slide");
    this.tabsInventory.remove();
    this.controlNavButtons.off("click.carrousel");
    this.slider.off("transitionend.carrousel");
    $(window).off("resize.carrousel");
    this.initCarrousel(this.config.shiftSlide);
  }

  public DisplaySlide(index: number, withTransition = true): void {
    if (this.slides.length === 0) {
      return;
    }
    const selectedSlide = this._slides.filter(`[data-index="${index}"]`);
    this._slides.removeClass("selected");
    if (!withTransition) {
      this.slider.addClass("no-transition");
    }
    this.centerSlider(selectedSlide);
    if (this.config.onSlideDisplay) {
      this.config.onSlideDisplay();
    }
    this._currentIndex = index;
  }

  private shiftSlide(dir: number, index: number = null): void {
    this._currentIndex += dir;

    if (index !== null) {
      this._currentIndex = index;
    } else if (this._currentIndex < 0) {
      this._currentIndex = this._slides.length - 1;
    } else if (this._currentIndex >= this._slides.length) {
      this._currentIndex = 0;
    }

    this.tabsInventory.removeClass("selected");
    this.tabsInventory
      .filter(`[data-index=${this._currentIndex}]`)
      .addClass("selected");

    this.setAnimationInterval();
    this.DisplaySlide(this._currentIndex);
  }

  private initCarrousel(shiftSlide: number): void {
    this.cloneTabs();
    this.cloneSlides();

    this.RegisterEvents();
    this.shiftSlide(null, shiftSlide);

    this.controlNavButtons.toggleClass("hide", this.slides.length <= 1);
    if (shiftSlide == 0) {
      this._slides.filter(`[data-index=0]`).addClass("selected");
    }
  }

  private centerSlider(selectedSlide: JQuery<HTMLElement> = null): void {
    const sliderWrapperWidth = this.sliderWrapper.width();
    const padding = (sliderWrapperWidth - selectedSlide.outerWidth(true)) / 2;
    const left = padding - selectedSlide.position().left;
    this.slider.css("left", left);
    const timeout = setTimeout(() => {
      this.slider.removeClass("no-transition");
      clearTimeout(timeout);
    }, 50);
  }

  private cloneTabs(): void {
    for (let i = 0; i < this._slides.length; i++) {
      const clone = this.slideTabClone.clone();

      clone.removeClass("clone");
      clone.attr("data-index", i);
      clone.on("click", () => {
        this.shiftSlide(null, i);
      });

      this.tabsInventory = this.tabsInventory.add(clone);
      this.tabsContainer.append(clone);
    }
  }

  private RegisterEvents(): void {
    this.controlNavButtons.on("click.carrousel", (event) => {
      const dir = $(event.currentTarget).attr("data-direction");
      this.shiftSlide(+dir);
    });

    this.slider.on("transitionend.carrousel", () => {
      const selectedSlide = this._slides.filter(
        `[data-index="${this._currentIndex}"]`,
      );
      selectedSlide.addClass("selected");
    });

    // re-center the slider on the selected
    // slide after a window reaize
    $(window).on("resize.carrousel", () => {
      clearTimeout(this.delayedSlideShiftTimer);
      this.delayedSlideShiftTimer = setTimeout(() => {
        this.shiftSlide(null, this._currentIndex);
        clearTimeout(this.delayedSlideShiftTimer);
      }, 250);
    });
  }

  private setAnimationInterval(): void {
    clearTimeout(this.animationInterval);
    this.animationInterval = null;

    //The second argument allow to remove autoplay via css for mobile
    if (this.autoplay && this.controls.is(":visible")) {
      this.animationInterval = setTimeout(() => {
        this.shiftSlide(1);
        clearTimeout(this.animationInterval);
      }, this.speed);
    }
  }

  private cloneSlides(): void {
    for (let i = 0; i < this._slides.length; i++) {
      this._slides.eq(i).attr("data-index", i);
    }
  }
}
