import "../../resources/less/Troubadour/LoggedOut/About.less";
import "../../resources/less/Troubadour/LoggedOut/Popups/InfoLandingVideoPopup.less";
import "../../resources/less/Troubadour/LoggedOut/Popups/LandingSignupPopup.less";
import NanoPopup from "../components/Popups/NanoPopup";
import HorizontalCarrousel from "../components/HorizontalCarrousel";
import JQuery from "jquery";

class About {
  private troubVideoPopup: NanoPopup;
  private caseStudyVideoPopup: NanoPopup;
  private readonly troubVideoPopupIframe: JQuery<HTMLIFrameElement>;
  private readonly caseStudyVideoPopupIframe: JQuery<HTMLIFrameElement>;
  private readonly openTroubVideoPopupBtn: JQuery<HTMLAnchorElement>;
  private readonly openCaseStudyVideoPopupBtn: JQuery<HTMLAnchorElement>;
  private carrousel: HorizontalCarrousel;
  private readonly carrouselContainer: JQuery<HTMLElement>;

  constructor() {
    this.openTroubVideoPopupBtn = $(".troub-video-link");
    this.openCaseStudyVideoPopupBtn = $(".case-study-video-link");
    const troubVideoPopup = $("#troub-video-popup");
    const caseStudyVideoPopup = $("#case-study-video-popup");
    this.troubVideoPopupIframe = troubVideoPopup.find("iframe");
    this.caseStudyVideoPopupIframe = caseStudyVideoPopup.find("iframe");
    this.carrouselContainer = $("#main-container").find(".carrousel-container");

    // troub video popup
    this.troubVideoPopup = new NanoPopup({
      target: "#troub-video-popup",
      onHide: () => {
        // reset video when popup is closed
        this.troubVideoPopupIframe.attr("src", "");
      },
    });
    // open video popup and load video src
    this.openTroubVideoPopupBtn.on("click", () => {
      this.troubVideoPopupIframe.attr(
        "src",
        this.troubVideoPopupIframe.attr("data-src"),
      );
      this.troubVideoPopup.Show();
    });

    // case study video popup
    this.caseStudyVideoPopup = new NanoPopup({
      target: "#case-study-video-popup",
      onHide: () => {
        // reset video when popup is closed
        this.caseStudyVideoPopupIframe.attr("src", "");
      },
    });
    // open video popup and load video src
    this.openCaseStudyVideoPopupBtn.on("click", () => {
      this.caseStudyVideoPopupIframe.attr(
        "src",
        this.caseStudyVideoPopupIframe.attr("data-src"),
      );
      this.caseStudyVideoPopup.Show();
    });

    // testimonials carousel
    this.InitCarrousel(this.carrouselContainer);
  }

  private InitCarrousel(container): void {
    this.carrousel = new HorizontalCarrousel({
      container: container,
      autoplay: true,
      shiftSlide: 1,
      speed: 15000,
    });
  }
}

$(window).on("load", () => {
  new About();
});
